import React from "react";
import PropTypes from "prop-types";
import { useSpring, animated as a } from "react-spring";

import chevron from "../../images/svg/chevron_secondary.svg";

const getWrapperStyles = (expanded) =>
  useSpring({
    padding: expanded ? "25px 5px" : "0px 5px",
  });

const getDescriptionStyles = (expanded) =>
  useSpring({
    opacity: expanded ? 1 : 0,
    color: "#7D95AF",
    fontSize: "14px",
    lineHeight: 2,
    // fontFamily: 'Lato, sans-serif',
    fontWeight: 300,
    display: expanded ? "block" : "none",
  });

const Expander = (props) => {
  const { title, description, expanded, clickHandler, index } = props;
  const wrapperStyles = getWrapperStyles(expanded);
  const descriptionStyles = getDescriptionStyles(expanded);

  return (
    <a.div style={wrapperStyles} className="eli">
      <div className="eli__info">
        <a.div className="eli__title">
          <span>{title}</span>
        </a.div>
        <a.div
          className="eli__description"
          style={descriptionStyles}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <a.div className="eli__toggle" onClick={() => clickHandler(index)}>
        <img
          className={expanded ? "eli__upwards" : "eli__down"}
          src={chevron}
          alt=""
        />
      </a.div>
    </a.div>
  );
};

Expander.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  expanded: PropTypes.bool,
  clickHandler: PropTypes.func,
  index: PropTypes.number,
  // sectionData: PropTypes.objectOf(PropTypes.any),
};
Expander.defaultProps = {
  title: "",
  description: "",
  expanded: false,
  clickHandler: null,
  index: null,
};

export default Expander;
