import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import CtaSecondary from "../Buttons/secondary-cta";
import Expander from "./Expander";
import FaqStyles from "./FaqStyles";

const { Title } = Typography;

const Faqs = (props) => {
  const { sectionData, className } = props;
  const { title, ctaInfo, list } = sectionData;
  const [state, setState] = useState({
    isExpanded: false,
    expandedIndex: null,
  });
  const onFaqClick = (idx) => {
    if (idx === state.expandedIndex) {
      setState(() => ({
        isExpanded: state.isExpanded,
        expandedIndex: null,
      }));
    } else {
      setState(() => ({
        isExpanded: state.isExpanded,
        expandedIndex: idx,
      }));
    }
  };
  return (
    <section className={`faqs ${className}`}>
      <Row>
        {title && (
          <Col lg={20} sm={24}>
            <div>
              <Title level={2}>
                {" "}
                {title}
                <span className="blue_dot">.</span>{" "}
              </Title>
            </div>
          </Col>
        )}
        {ctaInfo?.ctaTarget && ctaInfo?.ctaTitle && (
          <Col lg={4} sm={24}>
            <div>
              <CtaSecondary
                target={ctaInfo.ctaTarget}
                ctaTitle={ctaInfo.ctaTitle}
              />
            </div>
          </Col>
        )}
      </Row>
      {list.map((item, idx) => (
        <Expander
          key={`${idx.toString()}__0`}
          title={item.title}
          description={item.body}
          clickHandler={onFaqClick}
          expanded={state.expandedIndex === idx}
          index={idx}
        />
      ))}

      <FaqStyles />
    </section>
  );
};

Faqs.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
};
Faqs.defaultProps = {
  sectionData: "",
  className: "",
};

export default Faqs;
