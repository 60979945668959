import React from "react";
import { viewports, colors, fontFamily } from "../../style-vars";

const { mdOnly, mdOrSmaller, smOrSmaller } = viewports;

const FaqStyles = () => (
  <style jsx global>{`
    .faqs {
      padding: 40px 20%;
      border-radius: 8px;
      position: relative;
    }

    .faqs h2 {
      color: ${colors.black} !important;
      font-weight: 800 !important;
    }

    .faqs .cta-secondary {
      display: flex;
      top: 100%;
      bottom: 0;
      right: 0;
    }

    .faqs.mb-120 {
      margin-bottom: 120px;
    }

    .eli {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${colors.white};
      position: relative;
      border-bottom: 1px solid #eceef0;
    }
    // .eli:hover {
    //   cursor: pointer;
    // }

    .eli span {
      font-size: 16px;
      color: ${colors.black};
      font-weight: 800;
    }

    .eli__description {
      font-family: ${fontFamily} !important;
      font-size: 16px !important;
      margin-top: 15px !important;
      line-height: 180% !important;
      font-weight: 400 !important;
      color: ${colors.black200} !important;
      //padding-right: 10px !important;
      transform: rotate(0deg);
    }

    .eli__toggle {
      height: 64px;
      flex: 0 0 auto;
      border-radius: 12px;
      transform: rotate(90deg);
      transition: ease-in-out 0.3s;
      cursor: pointer;
      //align-self: flex-start;
      margin-right: 15px;
    }

    .eli__upwards {
      transform: rotate(-180deg);
      transition: ease-in-out 0.3s;
    }

    .eli__down {
      transform: rotate(0deg);
      transition: ease-in-out 0.3s;
    }

    @media ${mdOnly} {
      .faqs {
        padding: 40px 100px 80px 100px;
      }
    }

    @media ${mdOrSmaller} {
      .faqs .cta-secondary {
        display: table !important;
        margin: 0 auto;
        margin-bottom: 32px;
      }
      .faqs h2 {
        text-align: center;
      }
      .faqs.mb-120 {
        margin-bottom: 0;
      }

      //.eli {
      //  padding-right: 5px !important;
      //}
    }

    @media ${smOrSmaller} {
      .faqs {
        padding: 40px 32px 80px 32px;
      }
    }
  `}</style>
);

export default FaqStyles;
